<!--团队管理：人员管理-->
<template>
  <div class="">
    <div class="page-container">
      <div class="section">
<!--        <h2 class="h2Style">-->
<!--          人员管理-->
<!--        </h2>-->
        <!--搜索栏-->
        <div class="control-box1 h2Style">
          <div class="cb-left">
            <el-input
                v-model.trim="form.name"
                clearable
                placeholder="班组名称"
                style="width: 200px;"
                @clear="form.name = undefined"
                @keyup.enter.native="handleFilter"
            />
            <el-button
                class="control-item"
                icon="el-icon-search"
                type="primary"
                @click="handleFilter"
            >
              搜索
            </el-button>
          </div>

          <div class="cb-right">
            <el-button
                type="primary"
                icon="el-icon-plus"
                @click="addTeamUserShow()"
            >
              添加
            </el-button>
          </div>
        </div>
        <!--表格-->
        <div class="table-box" style="width: 100%">
          <el-table
              v-loading="listLoading"
              :data="teamUser"
              element-loading-text="Loading"
              border
              highlight-current-row
              style="width: 100%"
          >
            <!--昵称-->
            <el-table-column label="班组名称" align="left">
              <template slot-scope="scope">
                {{ scope.row.name }}
              </template>
            </el-table-column>
            <!--性别-->
            <el-table-column label="开始时间" align="left" width="100">
              <template slot-scope="scope">
                {{ scope.row.startTime }}
              </template>
            </el-table-column>
            <!--角色-->
            <el-table-column label="结束时间" align="left" width="150">
              <template slot-scope="scope">
                <span v-if="scope.row.endTime === ''">-</span>
                <span v-else>{{ scope.row.endTime }}</span>
              </template>
            </el-table-column>
            <!--手机号-->
            <el-table-column label="工作时间(小时)" align="left" width="150">
              <template slot-scope="scope">
                <span>{{ scope.row.workTime }}</span>
              </template>
            </el-table-column>
            <!--邮箱-->
            <el-table-column label="非工作时间(分钟)" align="left" width="150">
              <template slot-scope="scope">
                {{ scope.row.restTime }}
              </template>
            </el-table-column>
            <!--注册时间-->
            <el-table-column prop="created_at" label="创建时间" align="left" width="170">
              <template slot-scope="scope">
                <span>{{ scope.row.dcreateDate }}</span>
              </template>
            </el-table-column>
            <!--操作-->
            <!--        v-if="managePermission"-->
            <el-table-column label="操作" align="left" width="170">
              <template slot-scope="scope">
                <el-button
                    type="primary"
                    size="mini"
                    @click="updateTeamUser(scope.row)"
                >
                  编辑
                </el-button>

                <el-button
                    size="mini"
                    icon="el-icon-delete"
                    type="primary"
                    plain
                    @click="deleteTeamUser(scope.row)"
                >
                  删除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <!--分页-->
        <div class="pagination-box">
          <el-pagination
              :page-sizes="[10, 20, 30, 40]"
              :page-size="form.count"
              :total="totalCount"
              :current-page="form.page"
              layout="total, sizes, prev, pager, next, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>

      <!--弹出框：添加账号-->
      <el-dialog
          v-if="TeamUserShow"
          :visible.sync="TeamUserShow"
          :title="dateAccount"
          :close-on-click-modal="false"
          :modal-append-to-body="false"
          :append-to-body="false"
          width="680px"
      >
        <el-form ref="inviteForm" label-width="100px" :model="account" :rules="rules.inviteRules">
          <el-form-item label="班组名称:" prop="name">
            <el-input
                v-model="account.name"
                show-word-limit
                maxlength="30"
                clearable
                placeholder="请输入班组名称"
            />
            <!--     size="medium"       -->
          </el-form-item>
          <el-form-item label="开始时间:" prop="startTime" class="form-item">
            <template>
              <el-time-select
                  @change="handleStartTime"
                  placeholder="起始时间"
                  v-model="account.startTime"
                  :picker-options="{
                  start: '00:00',
                  step: '00:30',
                  end: '23:30'
                }">
              </el-time-select>
            </template>

<!--            <el-time-picker-->
<!--                v-model="account.startTime"-->
<!--                :picker-options="{selectableRange: '00:00:00 - 23:59:00'}"-->
<!--                @change="handleStartTime"-->
<!--                placeholder="请选择开始时间">-->
<!--            </el-time-picker>-->

          </el-form-item>

          <el-form-item label="结束时间:" prop="endTime" class="form-item">
            <el-time-select
                @change="handleEndTime"
                placeholder="结束时间"
                v-model="account.endTime"
                :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '23:30'
              }">
            </el-time-select>
<!--            <el-time-picker-->
<!--                v-model="account.endTime"-->
<!--                @change="handleEndTime"-->
<!--                :picker-options="{selectableRange: '00:00:00 - 23:59:00', minTime: account.startTime}"-->
<!--                placeholder="请选择结束时间">-->
<!--            </el-time-picker>-->

          </el-form-item>
          <el-form-item label="工作时间:" prop="" class="form-item">
            <el-input
                class="input-width"
                v-model="account.workTime"
                disabled
                placeholder="请输入工作时间"
            />
            <!--     size="medium"       -->
          </el-form-item>
          <el-form-item label="非工作时间:" prop="restTime" class="form-item">
            <el-input-number
                v-model="account.restTime"
                :max="720"
                :min="5"
                :step="5"
                :precision="0"
                label="休息时间"
                @change="handleChange"
            ></el-input-number>
            <span style="margin-left: 10px">分钟</span>
            <!--     size="medium"       -->
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="TeamUserShow = false">取 消</el-button>
          <el-button type="primary" :loading="addUserBtnLoading" @click="dateAccount === '添加班组' ? addTeamUser() : editConfirm()">
            确定
          </el-button>
        </div>
      </el-dialog>

      <!--弹出框：编辑-->
      <el-dialog
          v-if="teamUserEditShow"
          :visible.sync="teamUserEditShow"
          title="编辑账号"
          :close-on-click-modal="false"
          :modal-append-to-body="false"
          :append-to-body="true"
      >
        <div class="mr-70">
          <el-form ref="teamForm" :model="teamValues" :rules="rules.inviteRules">
            <el-form-item label="账号角色:" label-width="100px" class="">
            </el-form-item>
            <el-form-item label="班组:" label-width="100px" prop="team">
              <el-select
                  v-model="teamValues.team"
                  placeholder="请选择班组"
                  clearable
                  style="width: 100%"
              >
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" plain @click="teamUserEditShow = false">取 消</el-button>
          <el-button type="primary" :loading="editDetailBtnLoading" @click="editTeamUserRoles">
            确定
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import API from '@/api';
// import convert from "@/utils/convert";
export default {
  name: 'PersonManagement',
  data() {
    return {
      teamUser: [],
      TeamUserShow: false,
      teamUserEditShow: false,
      addUserBtnLoading: false,
      editDetailBtnLoading: false,
      listLoading: false,
      teamValues: {
        team: '',
      },
      // 弹窗标题
      dateAccount: '添加班组',
      // 添加账号
      account: {
        name: '',
        startTime: '',
        endTime: '',
        workTime: '',
        restTime: '',
      },
      leader: '',
      // 查询
      form: {
        name: '',
        page: 1,
        count: 10,
      },
      start: '',
      end: '',
      rules: {
        inviteRules: {
          name: [
            { required: true, message: '请输入班组名称', trigger: 'change' },
            {
              min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur',
            },
          ],
          startTime: [{ required: true, message: '请选择开始时间', trigger: 'change' }],
          endTime: [{ required: true, message: '请选择结束时间', trigger: 'change' }],
          workTime: [{ required: true, message: '请输入工作时间', trigger: 'change' }],
          restTime: [
            { required: true, message: '请输入休息时间', trigger: 'blur' },
            {
              pattern: /[1-9]\d*/, max: 720, message: '必须为正整数', trigger: 'change',
            },
          ],
        },
      },
      totalCount: 0,
      buttons: '',
    };
  },
  computed: {
  },
  created() {
  },
  mounted() {
    this.getTeamUser();
  },
  methods: {
    getTeamUser() {
      // console.log(this.form)
      API.getClassDate(this.form).then((response) => {
        this.teamUser = response.message.data.list;
        this.totalCount = response.message.data.totalCount;
      });
      // // 角色
      // API.CompanyRole().then((res) => {
      //   this.roleIds = [
      //     { id: '', name: '全部' }
      //   ]
      //   res.message.data.forEach((item) => {
      //     this.roleIds.push(item)
      //   })
      //
      // });
    },
    handleFilter() {
      this.form.count = 10;
      this.form.page = 1;
      this.getTeamUser();
    },
    // 开始时间获取值
    handleStartTime(value) {
      if (value === null) {
        this.account.workTime = '';
        return false;
      }
      this.start = value;
      if (this.account.endTime) {
        this.end = this.account.endTime;
        const time1 = this.start.split(':');
        const time1Arry = parseInt(time1[0] * 60) + parseInt(time1[1]);
        const time2 = this.end.split(':');
        const time2Arry = parseInt(time2[0] * 60) + parseInt(time2[1]);
        const workTime = (time2Arry - time1Arry) / 60;
        if (workTime <= 0) {
          this.account.workTime = ((time2Arry - time1Arry) / 60 + 24);
        } else {
          this.account.workTime = (time2Arry - time1Arry) / 60;
        }
      }

      // var h = value.getHours()
      // var m = value.getMinutes()
      // // var s = value.getSeconds()
      // // if (m< 10) {
      // //    m = '0' + m
      // // }
      // this.start = h + ':' + m + ':'
      // console.log(this.start)
      // if (this.end) {
      //   var time1 = this.start.split(':')
      //   var time1Arry = parseInt(time1[0]*60)+parseInt(time1[1])
      //   var time2 = this.end.split(':')
      //   var time2Arry = parseInt(time2[0]*60)+parseInt(time2[1])
      //   console.log(time2Arry - time1Arry)
      // }
    },
    handleEndTime(value) {
      if (value === null) {
        this.account.workTime = '';
        return false;
      }
      console.log(this.account.startTime);
      this.start = this.account.startTime;
      console.log(this.start);
      this.end = value;
      var time1 = this.start.split(':');
      var time1Arry = parseInt(time1[0] * 60) + parseInt(time1[1]);
      var time2 = this.end.split(':');
      var time2Arry = parseInt(time2[0] * 60) + parseInt(time2[1]);
      const workTime = (time2Arry - time1Arry) / 60;
      if (!this.account.startTime) {
        this.start = this.account.startTime;
        // var time1 = this.start.split(':');
        // var time1Arry = parseInt(time1[0] * 60) + parseInt(time1[1]);
        // var time2 = this.end.split(':');
        // var time2Arry = parseInt(time2[0] * 60) + parseInt(time2[1]);
        // const workTime = (time2Arry - time1Arry) / 60;
        if (workTime <= 0) {
          this.account.workTime = ((time2Arry - time1Arry) / 60 + 24);
        } else {
          this.account.workTime = (time2Arry - time1Arry) / 60;
        }
      }
      if (workTime <= 0) {
        this.account.workTime = ((time2Arry - time1Arry) / 60 + 24);
      } else {
        this.account.workTime = (time2Arry - time1Arry) / 60;
      }
      //
      // console.log(value)
      // var h = value.getHours()
      // var m = value.getMinutes()
      // // var s = value.getSeconds()
      // if (m< 10) {
      //   m = '0' + m
      // }
      // this.end = h + ':' + m + ':'
      // var time1 = this.start.split(':')
      // var time1Arry = parseInt(time1[0]*60)+parseInt(time1[1])
      // var time2 = this.end.split(':')
      // var time2Arry = parseInt(time2[0]*60)+parseInt(time2[1])
    },
    // 非工作时间
    handleChange(value) {
      // const number = value / 60;
      // if (number >= this.account.workTime) {
      //   this.$message({
      //     message: '非工作时间不能大于工作时间',
      //     showClose: true,
      //     type: 'warning',
      //   });
      // }
      console.log(value);
    },
    // 删除
    deleteTeamUser(row) {
      this.$confirm('确定删除当前班组吗？', '删除班组', {
        closeOnClickModal: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          API.deleteClassTeam(row.id).then((response) => {
            console.log(response);
            this.$message({
              message: response.message.message,
              showClose: true,
              type: 'success',
            });
            if (this.teamUser.length === 1 && this.form.page !== 1) {
              this.form.page -= 1;
            }
            this.getTeamUser();
          });
        });
    },
    addTeamUserShow() {
      // console.log(this.roleIds)
      this.account.name = '';
      this.account.startTime = '';
      this.account.endTime = '';
      this.account.workTime = '';
      this.account.restTime = 5;
      this.dateAccount = '添加班组';
      this.TeamUserShow = true;
    },
    updateTeamUser(row) {
      this.account = JSON.parse(JSON.stringify(row));
      console.log(this.account);
      this.dateAccount = '编辑班组';
      this.TeamUserShow = true;
    },
    editTeamUserRoles() {
      let roleIds = this.roleValues.join();
      if (this.curUserId === this.leader) {
        roleIds = `${this.leaderRole},${roleIds}`;
      }
      if (this.roleValues.length === 0) {
        roleIds = null;
      }
    },
    // 添加弹窗确定
    addTeamUser() {
      this.$refs.inviteForm.validate((v) => {
        if (v) {
          const number = this.account.restTime / 60;
          if (number >= this.account.workTime) {
            this.$message({
              message: '非工作时间不能大于工作时间',
              showClose: true,
              type: 'warning',
            });
            return;
          }
          this.addUserBtnLoading = true;
          const params = JSON.parse(JSON.stringify(this.account));
          API.addClassTeam(params).then((res) => {
            if (res.message.success === true) {
              this.TeamUserShow = false;
              this.addUserBtnLoading = false;
              this.$message.success('添加成功');
              this.getTeamUser();
            } else {
              this.$message.error(res.message.message);
            }
          }).catch(() => {
            this.addUserBtnLoading = false;
          }).finally(() => {
            this.addUserBtnLoading = false;
          });
        } else {
          return false;
        }
        return v;
      });
    },
    // 编辑弹窗确定
    editConfirm() {
      this.$refs.inviteForm.validate((v) => {
        if (v) {
          const number = this.account.restTime / 60;
          if (number >= this.account.workTime) {
            this.$message({
              message: '非工作时间不能大于工作时间',
              showClose: true,
              type: 'warning',
            });
            return;
          }
          this.addUserBtnLoading = true;
          const params = JSON.parse(JSON.stringify(this.account));
          API.editClassTeam(params, this.account.id).then((res) => {
            if (res.message.success === true) {
              this.TeamUserShow = false;
              this.addUserBtnLoading = false;
              this.$message.success('编辑成功');
              this.getTeamUser();
            } else {
              this.$message.error(res.message.message);
            }
          }).catch(() => {
            this.addUserBtnLoading = false;
          }).finally(() => {
            this.addUserBtnLoading = false;
          });
        } else {
          return false;
        }
        return v;
      });
    },
    handleSizeChange(val) {
      this.form.count = val;
      this.form.page = 1;
      this.getTeamUser();
    },
    handleCurrentChange(val) {
      this.form.page = val;
      this.getTeamUser();
    },
  },
};
</script>

<style lang="scss" scoped>
//.page-container{
//  padding: 40px;
//}
//.section{
//  padding: 30px 40px;
//}
.h2Style {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dcdfe6;
  font-size: 20px;
}
.PersonManagement {
  width: 100%;
}
.control-box1 {
  display: flex;
  justify-content: space-between;
}
.cb-left {
  display: -webkit-box;
}
.control-item {
  margin-left: 15px;
}
::v-deep .form-item{
  display: inline-block;
}
.input-width {
  width: 220px;
}
</style>
